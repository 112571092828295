
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@common/src/utils/common";
import dayjs from "dayjs";
import BtnRadio from "@/components/scope/btn-radio.vue";
import FilterBar from "@common/src/components/scope/filter-bar.vue";
import echarts from "echarts";
import appCode from "@common/src/utils/app-code";
const statement = namespace("statement");
const base = namespace("base");
const management = namespace("management");
const service = namespace("service-item");
const parts = namespace("parts");
@Component({
  components: { BtnRadio, FilterBar },
  filters: {}
})
export default class Statement extends Vue {
  @statement.Action getTurnOverAnalysisModel;
  @base.Action getDictionaryList;
  @management.Action getStoreList;
  @service.Action getServiceList;
  @parts.Action getPartsList;

  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  searchForm: any = {
    storeCode: "", // 门店名称
    area: "", // 所在地区
    stateName: "", // 省级名称
    cityName: "", // 市级名称
    districtName: "", // 县级名称
    timeFrameType: "", // 时间范围类型, date-按日,month-按月
    startTime: "", // 开始时间 格式: 按日为yyyyMMdd, 按月为yyyyMM
    endTime: "", // 结束时间 格式: 按日为yyyyMMdd, 按月为yyyyMM
    businessType: "", // 服务类型，services-服务项目,parts-零配件
    businessCodeList: [] // 服务编码列表
  };
  contentList: any = [];
  pieChartRadio: any = "0";
  barChartRadio: any = "0";

  created() {
    if (localStorage.getItem("searchFormData")) {
      let data = JSON.parse(localStorage.getItem("searchFormData"));
      localStorage.removeItem("searchFormData");
      this.searchForm = data;
    } else {
      if (appCode === "speedbiz-operation") {
        this.searchStore("");
      } else if (appCode === "speedbiz") {
        this.searchService("");
        this.searchParts("");
      }
      this.getCurrentTime();
    }
    this.init();
  }
  getCurrentTime() {
    // 获取当前时间
    let timestamp = new Date().getTime() - 24 * 60 * 60 * 1000;
    let currentTime = new Date(timestamp);
    let year = String(currentTime.getFullYear());
    let month = this.addZero(currentTime.getMonth() + 1);
    let day = this.addZero(currentTime.getDate());
    this.searchForm.timeFrameType = "date";
    this.searchForm.startTime = year + month + "01";
    this.searchForm.endTime = year + month + day;
  }
  addZero(num) {
    return num < 10 ? "0" + num : String(num);
  }
  mounted() {}
  storeList: any = [];
  serviceList: any = [];
  partsList: any = [];
  get searchList() {
    if (localStorage.getItem("searchListData")) {
      let data = JSON.parse(localStorage.getItem("searchListData"));
      localStorage.removeItem("searchListData");
      data.forEach(item => {
        if (item.type === "select-view") {
          item.selectList.forEach(part => {
            if (part.value === "services") {
              this.serviceList = part.itemSelectList;
              part.itemSelectList = this.serviceList;
              part.itemSearchFun = this.searchService;
            }
            if (part.value === "parts") {
              this.partsList = part.itemSelectList;
              part.itemSelectList = this.partsList;
              part.itemSearchFun = this.searchParts;
            }
          });
        }
      });
      return data;
    }
    let storeRow = [
      {
        label: this.$t("v210831.store-name"),
        type: "select-search",
        value: "",
        prop: "storeCode",
        selectList: this.storeList,
        searchFun: this.searchStore
      },
      {
        label: this.$t("setting.region"),
        type: "area",
        value: "",
        labels: [],
        prop: "area",
        anyLevel: true,
        labelWidth: "85px"
      }
    ];
    let timeRow = [
      {
        label: this.$t("v210831.time-frame"),
        type: "select-view",
        value: "",
        prop: "timeFrameType",
        // 时间范围区间必须是同一年
        isSameYear: true,
        selectList: [
          {
            value: "date",
            label: this.$t("v210831.query-by-day"),
            itemValue: "",
            itemType: "daterange",
            itemProp: "dateValue",
            itemValueFormat: "yyyyMMdd"
          },
          {
            value: "month",
            label: this.$t("v210831.query-by-month"),
            itemValue: "",
            itemType: "monthrange",
            itemProp: "dateValue",
            itemValueFormat: "yyyyMM"
          }
        ]
      }
    ];
    let eventRow = [
      {
        label: "项目/零配件筛选",
        type: "select-view",
        value: "",
        prop: "businessType",
        labelWidth: "120px",
        selectList: [
          {
            value: "services",
            label: "服务项目",
            itemValue: [],
            itemType: "select-search",
            itemProp: "businessCodeList",
            itemSelectList: this.serviceList,
            itemSearchFun: this.searchService
          },
          {
            value: "parts",
            label: "零配件",
            itemValue: [],
            itemType: "select-search",
            itemProp: "businessCodeList",
            itemSelectList: this.partsList,
            itemSearchFun: this.searchParts
          }
        ]
      }
    ];
    if (appCode === "speedbiz-operation") {
      return [...storeRow, ...timeRow];
    } else if (appCode === "speedbiz") {
      timeRow[0]["labelWidth"] = "120px";
      return [...timeRow, ...eventRow];
    }
    return [];
  }
  searchStore(val = "") {
    this.getStoreList({
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
    });
  }
  searchService(val = "") {
    this.getServiceList({
      status: "1",
      pageNum: 1,
      pageSize: 10,
      searchKey: val
    }).then(data => {
      this.serviceList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.serviceName;
            item.value = item.serviceCode;
            return item;
          })
        : [];
      console.log(this.serviceList, 3333333331);
    });
  }
  searchParts(val = "") {
    this.getPartsList({
      status: "1",
      pageNum: 1,
      pageSize: 10,
      partsName: val
    }).then(data => {
      this.partsList = this.$safeValue(data, "data.list", []).map(item => {
        item.label = item.partsName;
        item.value = item.partsCode;
        return item;
      });
    });
  }
  init() {
    this.getChartData();
  }
  dataLastUpdateTime: any = "";
  getChartData() {
    this.getTurnOverAnalysisModel(this.searchForm).then(data => {
      if (data.data.dataLastUpdateTime) {
        this.dataLastUpdateTime = data.data.dataLastUpdateTime;
      }
      let {
        payAmountStatistics,
        orderAmountStatistics,
        orderNumberStatistics,
        payAmountMonthlyList,
        orderAmountMonthlyList,
        orderNumberMonthlyList,
        orderCollectionMonthlyList
      } = data.data;
      console.log(data.data, this.$t("v210831.chart"));
      /**
       * @refName
       *  piePayChart  饼图 实收金额   -----payAmountStatistics
       *  pieOrderChart  饼图 订单金额   -----orderAmountStatistics
       *  pieNumberChart  饼图 台次   -----orderNumberStatistics
       *  barPayChart  柱状图 实收金额   -----payAmountMonthlyList
       *  barOrderChart  柱状图 订单金额   -----orderAmountMonthlyList
       *  barNumberChart  柱状图 台次   -----orderNumberMonthlyList
       *  lineChart  折线图   -----orderCollectionMonthlyList
       */
      let piePayChartData = {
        data: [
          {
            name: this.$t("dashboard.repair-maintenance"),
            value: payAmountStatistics.maintenanceAmount
          },
          {
            name: this.$t("dashboard.wash-order"),
            value: payAmountStatistics.carWashAmount
          },
          {
            name: this.$t("dashboard.retail-order"),
            value: payAmountStatistics.retailAmount
          }
        ],
        total: payAmountStatistics.totalAmount,
        text: this.$t("v210831.total-paid-in"),
        tip: this.$t("v210831.paid-in-amount")
      };
      this.setChart(piePayChartData, "piePayChart", "pie");
      let pieOrderChartData = {
        data: [
          {
            name: this.$t("dashboard.repair-maintenance"),
            value: orderAmountStatistics.maintenanceAmount
          },
          {
            name: this.$t("dashboard.wash-order"),
            value: orderAmountStatistics.carWashAmount
          },
          {
            name: this.$t("dashboard.retail-order"),
            value: orderAmountStatistics.retailAmount
          }
        ],
        total: orderAmountStatistics.totalAmount,
        text: this.$t("v210831.total-order-amount"),
        tip: this.$t("finance.order-amount")
      };
      this.setChart(pieOrderChartData, "pieOrderChart", "pie");
      let pieNumberChartData = {
        data: [
          {
            name: this.$t("dashboard.repair-maintenance"),
            value: orderNumberStatistics.maintenanceNumber
          },
          {
            name: this.$t("dashboard.wash-order"),
            value: orderNumberStatistics.carWashNumber
          },
          {
            name: this.$t("dashboard.retail-order"),
            value: orderNumberStatistics.retailNumber
          }
        ],
        total: orderNumberStatistics.totalNumber,
        text: this.$t("v210831.summary-of-sets"),
        tip: this.$t("v210831.number-of-sets"),
        isNumber: true
      };
      this.setChart(pieNumberChartData, "pieNumberChart", "pie");
      let barPayChartData = {
        data1: payAmountMonthlyList.map(v => v.maintenanceAmount),
        data2: payAmountMonthlyList.map(v => v.carWashAmount),
        data3: payAmountMonthlyList.map(v => v.retailAmount),
        tip: payAmountMonthlyList,
        unit: this.$t("v210801.element")
      };
      this.setChart(barPayChartData, "barPayChart", "bar");
      let barOrderChartData = {
        data1: orderAmountMonthlyList.map(v => v.maintenanceAmount),
        data2: orderAmountMonthlyList.map(v => v.carWashAmount),
        data3: orderAmountMonthlyList.map(v => v.retailAmount),
        tip: orderAmountMonthlyList,
        unit: this.$t("v210801.element")
      };
      this.setChart(barOrderChartData, "barOrderChart", "bar");
      let barNumberChartData = {
        data1: orderNumberMonthlyList.map(v => v.maintenanceNumber),
        data2: orderNumberMonthlyList.map(v => v.carWashNumber),
        data3: orderNumberMonthlyList.map(v => v.retailNumber),
        tip: orderNumberMonthlyList,
        unit: this.$t("v210831.number-of-sets"),
        isNumber: true
      };
      this.setChart(barNumberChartData, "barNumberChart", "bar");
      let lineChartData = {
        data1: orderCollectionMonthlyList.map(v => (Number(v.orderAmount) ? v.orderAmount : null)),
        data2: orderCollectionMonthlyList.map(v => (Number(v.payAmount) ? v.payAmount : null)),
        tip: orderCollectionMonthlyList,
        unit: this.$t("v210801.element")
      };
      this.setChart(lineChartData, "lineChart", "line");
    });
  }
  setChart(data, refName, type) {
    let MyChart = echarts.init(this.$refs[refName]);
    let options = {};
    if (type === "pie") {
      // 饼图pie
      options = {
        color: ["#2278F5", "#00B478", "#FFC300"],
        tooltip: {
          trigger: "item",
          formatter: params => {
            return `${params.name}<br/>${data.tip}: ${
              data.isNumber ? params.value : this.$localMark.currencySymbol + splitThousands(params.value)
            }(${params.percent}%)`;
          }
        },
        legend: {
          bottom: "0%",
          left: "center",
          x: "center",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 24
        },
        title: {
          show: true,
          text: data.text,
          subtext: splitThousands(data.total),
          top: "43%",
          left: "center",
          textStyle: {
            color: "rgba(0, 0, 0, 0.45)",
            fontWeight: 400,
            fontSize: 12
          },
          subtextStyle: {
            color: "rgba(0, 0, 0, 0.85)",
            fontWeight: 500,
            fontSize: 18
          }
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            data: data.data
          }
        ]
      };
    } else if (type === "bar") {
      // 柱状图bar
      options = {
        color: ["#2278F5", "#00B478", "#FFC300"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: params => {
            // console.log(params);
            let str = `${data.tip[params[0].dataIndex].monthParam}<br/>`;
            params.forEach(item => {
              str += `${item.marker}${item.seriesName}: ${
                Number(item.value)
                  ? data.isNumber
                    ? item.value
                    : this.$localMark.currencySymbol + splitThousands(item.value)
                  : "-"
              }<br/>`;
            });
            return str;
          }
        },
        legend: {
          data: [
            this.$t("dashboard.repair-maintenance"),
            this.$t("dashboard.wash-order"),
            this.$t("dashboard.retail-order")
          ],
          bottom: "0%",
          left: "center",
          x: "center",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 24
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true
            },
            data: [
              this.$t("v210831.january"),
              this.$t("v210831.february"),
              this.$t("v210831.march"),
              this.$t("v210831.april"),
              this.$t("v210831.may"),
              this.$t("v210831.june"),
              this.$t("v210831.july"),
              this.$t("v210831.august"),
              this.$t("v210831.september"),
              this.$t("v210831.october"),
              this.$t("v210831.november"),
              this.$t("v210831.december")
            ]
          }
        ],
        yAxis: [
          {
            name: this.$t("v210831.company:") + data.unit,
            axisTick: { show: false },
            type: "value"
          }
        ],
        series: [
          {
            name: this.$t("dashboard.repair-maintenance"),
            type: "bar",
            barGap: 0,
            data: data.data1
          },
          {
            name: this.$t("dashboard.wash-order"),
            type: "bar",
            data: data.data2
          },
          {
            name: this.$t("dashboard.retail-order"),
            type: "bar",
            data: data.data3
          }
        ]
      };
    } else if (type === "line") {
      // 折线图line
      options = {
        color: ["#2278F5", "#00B478", "#FFC300"],
        tooltip: {
          trigger: "axis",
          formatter: params => {
            // console.log(params);
            let str = `${data.tip[params[0].dataIndex].monthParam}<br/>`;
            params.forEach(item => {
              str += `${item.marker}${item.seriesName}: ${
                Number(item.value) ? this.$localMark.currencySymbol + splitThousands(item.value) : "-"
              }<br/>`;
            });
            return str;
          }
        },
        legend: {
          data: [this.$t("finance.order-amount"), this.$t("v210831.paid-in-amount")],
          bottom: "0%",
          left: "center",
          x: "center",
          itemGap: 24
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true
            },
            data: [
              this.$t("v210831.january"),
              this.$t("v210831.february"),
              this.$t("v210831.march"),
              this.$t("v210831.april"),
              this.$t("v210831.may"),
              this.$t("v210831.june"),
              this.$t("v210831.july"),
              this.$t("v210831.august"),
              this.$t("v210831.september"),
              this.$t("v210831.october"),
              this.$t("v210831.november"),
              this.$t("v210831.december")
            ]
          }
        ],
        yAxis: [
          {
            name: this.$t("v210831.company:") + data.unit,
            axisTick: { show: false },
            type: "value"
          }
        ],
        series: [
          {
            name: this.$t("finance.order-amount"),
            type: "line",
            barGap: 0,
            data: data.data1
          },
          {
            name: this.$t("v210831.paid-in-amount"),
            type: "line",
            data: data.data2
          }
        ]
      };
    }
    MyChart.setOption(options);
    window.addEventListener("resize", () => {
      MyChart.resize();
    });
  }
  handlePieChange(value) {
    this.pieChartRadio = value;
  }
  handleBarChange(value) {
    this.barChartRadio = value;
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "area") {
          this.searchForm.stateName = item.labels[0] || "";
          this.searchForm.cityName = item.labels[1] || "";
          this.searchForm.districtName = item.labels[2] || "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
        if (item.type === "select-view") {
          item.selectList.forEach(val => {
            if (val.value === item.value) {
              if (val.itemProp === "dateValue") {
                this.searchForm.startTime = val.itemValue[0] || "";
                this.searchForm.endTime = val.itemValue[1] || "";
              } else {
                this.searchForm[val.itemProp] = val.itemValue;
              }
            }
          });
        }
      }
    });
    this.getChartData();
  }
}
